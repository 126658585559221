import React, { useState, useEffect } from 'react';
import "./inventory.css";
import { InventoryContainer, InventoryNav, LatestInventoryContainer, Header, Footer, InventoryHelmet } from '../../components';


const Inventory = () => {
    const [inventoryData, setInventoryData] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [showLatestInventory, setShowLatestInventory] = useState(true);

    const handleNavItemSelect = (data, category, subCategory) => {
        setInventoryData(data);
        setCategoryName(category);
        setSubCategoryName(subCategory);
        setShowLatestInventory(false);
    };

    const handleLatestClick = () => {
        setShowLatestInventory(true); // Show the LatestInventoryContainer on Featured click
    };

    return (
        <div className='inventory'>
            <InventoryHelmet/>
            <Header />
            <InventoryNav onNavItemSelect={handleNavItemSelect} onLatestClick={handleLatestClick} />
            {showLatestInventory ? <LatestInventoryContainer /> 
            : <InventoryContainer inventoryData={inventoryData}
                category={categoryName}
                subCategory={subCategoryName}/>}
            <Footer />
        </div>
    )
}

export default Inventory
