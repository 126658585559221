import React from 'react';
import "./history.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/esm/Image';
import mike from "../../assets/mike.png";
import staffPhoto from "../../assets/staffPhoto.jpg"

const History = () => {
    return (
        <div className='history'>
            <Container className='desktop'>
                <Row >
                    <Col className='history-content'>
                        <h1>History</h1>
                        <p className='history-paragraph'>Founded  in 1996, The Old House Parts Company evolved from a 
                        strong appreciation of architecture, antiques, and craftsmanship coupled with the realization 
                        that high quality building materials were being discarded during remodeling and demolition projects.  
                        Our mission is to provide convenient, easy access to our large inventory of these high quality 
                        pieces for restoration projects and those seeking to enhance their homes and properties with authenticity, 
                        quality, and character.</p>
                    </Col>
                    <Col>
                        <Image loading="lazy" src={staffPhoto} fluid></Image>
                    </Col>
                </Row>
                <Row className='people'>
                    <Row>
                        <h1 className='text-center'>People</h1>
                    </Row>
                    <Row className='people-content'>
                        <Col><Image loading="lazy" className='people-image' src={mike} ></Image></Col>
                        <Col>
                            <Row><h3>Mike Thompson</h3></Row>
                            <Row><h5 className='pt-1'>Owner</h5></Row>
                            <Row><p className='pt-3'>Mike has been the heart of The Old House Parts Company, specializing in antique
                                architectural salvage and old hardware. Since 2018, he proudly
                                leads OHPC, continuing the legacy of
                                preserving architectural history. OHPC's former founder Tom Joyal's departure was marked
                                by his heartfelt words: "Mike's been vital to OHPC's success for
                                nearly two decades. Customers consistently commend his knowledge
                                and warm demeanor. Tiffany and I wish him and his family the best,
                                knowing hundreds of homes will be recycled and renewed." Under Mike's
                                guidance, we remain the beloved OHPC that our customers cherish!</p></Row>
                        </Col>
                    </Row>
                </Row>
            </Container>

            <Container className='mobile'>
                <Row >
                    <h1>History</h1>
                    <p className='history-paragraph'>Founded  in 1996, The Old House Parts Company evolved from a 
                        strong appreciation of architecture, antiques, and craftsmanship coupled with the realization 
                        that high quality building materials were being discarded during remodeling and demolition projects.  
                        Our mission is to provide convenient, easy access to our large inventory of these high quality 
                        pieces for restoration projects and those seeking to enhance their homes and properties with authenticity, 
                        quality, and character.</p>

                    <Image loading='lazy' src='https://picsum.photos/550/300' fluid></Image>

                </Row>
                <Col className='people text-center '>
                    <Row>
                        <h1 className='text-center'>People</h1>
                    </Row>
                    <Row className='people-content justify-content-center'>
                        <Image loading='lazy' className='owner-img' src={mike} ></Image>
                        <Row><h3 className='pt-5'>Mike Thompson</h3></Row>
                        <Row><h5 className='pt-1'>Owner</h5></Row>
                        <Row><p className='pt-3'>Mike has been the heart of OHPC, specializing in antique
                            architectural salvage with a particular love for antique hardware. Since 2018, he proudly
                            leads The Old House Parts Company, continuing the legacy of
                            preserving architectural history. Founder Tom Joyal's departure was marked
                            by his heartfelt words: "Mike's been vital to OHPC's success for
                            nearly two decades. Customers consistently commend his knowledge
                            and warm demeanor, and Tiffany and I wish him and his family the best,
                            knowing hundreds of homes will be recycled and renewed." Under Mike's
                                guidance, we remain the beloved OHPC that our customers cherish!</p>
                        </Row>
                    </Row>
                </Col>
            </Container>
        </div>


    )
}

export default History
