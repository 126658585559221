import React from 'react';
import "./visitus.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import maine from '../../assets/maine.svg'
import { Link, useNavigate } from 'react-router-dom';

const VisitUs = () => {
    const navigate = useNavigate(); 

    const handleNavItemClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0); 
    };

    return (
        <div className='visit'>
            <Container>
                <Row className='content'>
                    <Col xs={12} md={8} className='pt-5'>
                        <Row>
                            <h2>Come Visit Us!</h2>
                        </Row>
                        <Row className='info pt-4 pb-5' >
                            <p>Embark on a journey to The Old House Parts Company, in the charming coastal town of Kennebunk, 
                                Maine, just one and a half hours north of Boston. An antique architectural salvage mecca, our vast, meticulously 
                                organized inventory resides in an 11,500 square foot 1872 freighthouse. While our online presence captures 
                                only a glimpse of what's inside, the true allure awaits those who visit.</p>
                        </Row>
                        <Row className='learn-btn' xs={2} md={3}>
                            <Link to='/about' onClick={() => handleNavItemClick("/about")}>
                                <Button variant="outline-dark"><h4>Learn More</h4></Button>
                            </Link>
                        </Row>
                    </Col>
                    <Col className='maine' md={4}>
                        <Image src={maine} fluid></Image>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default VisitUs
