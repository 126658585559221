import React from 'react';
import {Helmet} from 'react-helmet-async';
import metaImage from "../../assets/gallery-img/store.png";

const ContactHelmet = () => {
    const socialLinks = {
        facebook: 'https://www.facebook.com/oldhousepartsco/',
        instagram: 'https://www.instagram.com/oldhousepartsco/',
        youtube: 'https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw',
      };
    
      return (
        <div>
          <Helmet>
            <title>Old House Parts - Contact</title>
            <meta name="description" content="We value your connection with us. Whether you have inquiries, want to discuss a project, or simply share your thoughts, we're here to listen." />
            <meta name="keywords" content="Old House Parts, Antique Shop, Kennebunk, Maine, Architectural Salvage, Collectibles, Portland, Boston, Store Hours, Contact" />
    
            <meta property="og:url" content="https://www.oldhousepartsco.com" />
            <meta property="og:title" content="Old House Parts Co." />
            <meta property="og:description" content="Explore our collection of vintage furniture, art, and collectibles at Old House Parts Co. in Kennebunk, Maine." />
            <meta property="og:image" content={metaImage} />
    
            {/* Facebook */}
            <meta property="article:publisher" content={socialLinks.facebook} />
            {/* Instagram */}
            <meta property="instagram:site" content={socialLinks.instagram} />
            {/* YouTube */}
            <meta property="youtube:channel" content={socialLinks.youtube} />
          </Helmet>
        </div>
      )
}

export default ContactHelmet
